.cover {
    &__container {
        display: flex;
        height: auto;
        gap: 30px;
        position: relative;
        justify-content: flex-end;
    }

    &__image-wrapper {
        width: calc(50% - 35px);
        height: 100%;
        overflow: hidden;
        border-radius: $border-radius;
        min-height: 280px;
        position: absolute;
        overflow: hidden;
        left: 15px;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    &__informations {
        width: calc(50% + 5px);
        height: fit-content;
        min-height: 280px;
        background-color: $color-light;
        border-radius: $border-radius;
        padding: 50px 40px;
        display: flex;
        position: relative;

        &-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 5px;
        }

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            bottom: -14px;
            right: 10px;
            transform: rotate(30deg);
            width: 30px;
            height: 40px;
            background-image: url($urlSpriteImage + "theme-icons/forme_titre.svg");
            background-size: cover;
        }
    }

    &__title {
        font-family: $font-family;
        font-size: toRem(40);
        font-weight: $font-weight-extrabold;
        color: color-dark;
        margin: 0;
    }

    &__intro {
        font-family: $font-family-second;
        font-size: toRem(18);
        font-weight: $font-weight-bold;
        color: color-dark;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {
    .cover {
        &__image-wrapper {
            height: 340px;
            width: unset;
            position: static;
        }

        &__container {
            flex-direction: column;
            position: static;
            justify-content: baseline;
            gap: 20px;
        }

        &__informations {
            width: unset;
            min-height: 240px;
            padding: 25px 30px;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .cover {
        &__image-wrapper {
            height: 180px;
            width: unset;
        }

        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }

        &__image-wrapper {
            height: 255px;
        }
    }
}
