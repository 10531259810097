//======================================================================================================
// Contact base
//======================================================================================================
// Component badge
.contact-badge {
    display: flex;
    align-items: center;

    &__icon {
        flex: none;
        width: 26px;
        height: 26px;
        margin-right: 8px;
        fill: $color-text;
    }

    &__link {
        display: flex;
        align-items: center;
        text-decoration: underline;
        word-break: break-all;
        transition: {
            property: background-color, border-color, color, opacity, text-decoration-color;
            duration: $duration;
            timing-function: $timing;
        }

        &:hover,
        &:focus {
            text-decoration-color: transparent;

            // svg {
            //     fill: $color-link--hover;
            // }
        }

        svg {
            width: 20px;
            height: 20px;
            fill: $color--card;
            transition: {
                property: fill;
                duration: $duration;
                timing-function: $timing;
            }
        }

        &--underlinenone {
            text-decoration: none;
        }
    }

    &__additional {
        margin-top: 10px;

        &--no-margin {
            margin-top: 0;
        }
    }

    &--icon-top {
        align-items: flex-start;

        .contact-badge__icon {
            margin-top: -2px; // fix for margin on svg
        }
    }

    &--secondary {
        padding: 7px 0;

        .contact-badge__icon {
            box-sizing: content-box;
            @include size($btn-action-size);
            margin-right: 12px;
            background-color: $color-white;
            border: $btn-action-width $btn-action-style $btn-action-border-color;
            border-radius: $btn-action-border-radius;
            fill: $btn-action-color;
            transition: {
                property: background-color, border-color, fill;
                duration: $duration;
                timing-function: $timing;
            }
        }

        &:hover,
        &:focus {
            .contact-badge__icon {
                background-color: $btn-action-bg--hover;
                border-color: $btn-action-border-color--hover;
                fill: $btn-action-color--hover;
            }
        }
    }
}

//======================================================================================================
// Contact Single
//======================================================================================================
.contact-single {
    // Container
    .contact-container {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
