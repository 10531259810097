.search-container {
    display: flex;
    position: relative;
    align-items: center;
    z-index: 1;

    #searchInput {
        height: $search-input-height;
        padding: 24px 70px 24px 24px;
        background-color: $color-light;
        border-color: $search-input-border-color;
        box-sizing: border-box;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        border-radius: 50px;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }

        &:focus {
            border-color: $search-input-border-color--active;
        }

        &::placeholder {
            font-style: italic;
            color: $color-dark;
        }
    }

    .search-svg {
        @include flex($alignItems: center, $justifyContent: center);
        @include absolute($top: 50%, $right: 15px);
        transform: translateY(-50%);
        @include size(auto);
        background-color: $search-input-button-bg;
        border: none;
        border-radius: $search-input-button-radius;
        cursor: pointer;
        transition: {
            property: background-color, border-color;
            duration: $duration;
            timing-function: $timing;
        }

        svg {
            @include size($search-input-button-size);
            fill: $search-input-button-color;
            transition: fill $duration $timing;
        }

        &:hover,
        &:focus {
            background-color: $search-input-button-bg--active;
            border-color: $search-input-button-border-color--active;

            svg {
                fill: $search-input-button-color--active;
            }
        }
    }

    &-open {
        input {
            border-bottom: none;
            border-left: none;
            border-right: none;
        }
    }
}

.searchResult {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $color-light;
    border-top: 1px solid $color-gray;
    width: 100%;
    left: 0;
    padding-top: 43px;
    top: -44px;
    border-radius: 30px;
    z-index: 0;
    overflow: hidden;
    box-shadow: 0px 1px 10px 0px #0000001a;

    li {
        position: relative;
        background-color: $color-light;
        border-bottom: 1px solid $color-gray;
        transition: all $duration ease-in-out;
        cursor: pointer;

        &:nth-child(1) {
            border-top: 1px solid $color-gray;
        }

        a {
            display: flex;
            align-items: center;
            width: 75%;
            height: 100%;
            padding: 15px 24px;
            font-family: $font-family;
            transition: {
                property: color, background-color, padding-left;
                duration: $duration;
                timing-function: $timing;
            }
        }

        .resultCPT {
            position: absolute;
            right: 25px;
            top: 50%;
            pointer-events: none;
            color: rgba($color-dark, 0.8);
            transform: translate(0, -50%);
            transition: color $duration $timing;
        }

        &.no-results {
            background-color: $color-light;
            span {
                display: flex;
                width: 100%;
                height: 100%;
                padding: 15px 24px;
                color: $color-dark;
            }

            &:hover {
                span {
                    color: $color-white;
                }
            }
        }

        &:hover {
            background-color: $color-dark;
            color: $color-white;
            padding-left: 5px;
        }

        &:not(.no-results) {
            li:hover {
                padding-left: 5px;
                background-color: $color-dark;
                color: $color-white;

                &::after {
                    color: $color-white;
                }
            }

            &.selected,
            &:hover,
            &:focus {
                .resultCPT {
                    color: $color-white;
                }
            }
        }

        &:last-child {
            border-bottom: 0;
        }

        &.selected {
            padding-left: 5px;
            background-color: $color-dark;
            color: $color-white;
            a {
                &::after {
                    color: $color-white;
                }
            }
        }

        &.show-all a {
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;

            &::after {
                content: "\f344";
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 10px;
                font: normal 1.125rem/1 dashicons;
                color: $color-text;
                transition: color $duration $timing;
            }

            &:hover {
                &::after {
                    color: $color-white;
                }
            }
        }
    }
}

body.search-open {
    overflow: hidden;
}

body.search {
    .breadcrumb {
        display: none;
    }
    .container--search {
        padding-top: 30px;
    }
}

.search-popup {
    position: fixed;
    top: 0;
    z-index: 15;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: $color-bg--neutral;
    overflow: hidden;

    .container {
        position: relative;
        height: 100%;

        .search-popup__content {
            position: relative;
            top: 30%;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        label {
            position: relative;
            display: flex;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto 20px auto;
            padding-bottom: 0;
            border: 0;
            font-family: $font-family--heading;
            font-size: $font-size--1;
            font-weight: $font-weight--heading;
            color: $color-text;
            text-transform: none;
        }

        .search-container {
            display: flex;
            position: relative;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto;
        }

        .searchResult {
            width: calc(100% - 200px);
            left: 100px;
        }
    }

    .search-close {
        @include flex($alignItems: center, $justifyContent: center);
        cursor: pointer;
        @include absolute($top: 5%, $right: 0);
        @include size(40px);
        margin: 0;
        padding: 0;
        background-color: $color-dark;
        border: none;
        border-radius: $border-radius--round;
        transition: {
            property: background-color, border-color;
            duration: $duration;
            timing-function: $timing;
        }

        svg {
            @include size(40px);
            fill: $color-white;
            transition: fill $duration $timing;
        }

        &:hover,
        &:focus {
            background-color: transparent;
            border-color: $color-main;

            svg {
                fill: $color-main;
            }
        }
    }
}

// //======================================================================================================
// // Search Page
// //======================================================================================================
.filter {
    .search-container {
        .searchInput:focus {
            border-color: $search-input-border-color--active !important;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .search-popup .container .search-container,
    .search-popup .container label {
        width: calc(100% - 140px);
    }
    .search-popup .container .searchResult {
        left: 70px;
        width: calc(100% - 140px);
    }
}

// 960
@media screen and (max-width: $medium) {
    .search-popup .container .search-container,
    .search-popup .container label {
        width: 100%;
    }

    .search-popup {
        &::before {
            content: "";
            width: 315px;
            height: 430px;
            right: -125px;
            top: 107px;
            position: absolute;
            background-color: $color-dark;
            -webkit-mask-image: url($urlSpriteImage + "theme-icons/forme_fond.svg");
            mask-image: url($urlSpriteImage + "theme-icons/forme_fond.svg");
            mask-size: cover;
            opacity: 0.1;
            transform: scaleX(-1);
            z-index: -1;
        }

        #searchInput {
            background-color: #fff;
            border: none;
        }
    }

    .search-container {
        position: relative;

        .searchInput {
            border: none;
            background-color: $color-light;

            &::placeholder {
                font-family: $font-family-second;
                font-size: toRem(16);
                font-weight: 400;
                line-height: 25px;
            }
        }
    }

    .searchResult {
        // display: none !important;
    }
    .search-popup .container .searchResult {
        left: 0;
        width: 100%;
        background: $color-white;

        li {
            background-color: $color-white;

            &:hover {
                background: $color-dark;
                color: $color-white;
            }
        }
    }

    .search-popup .container .search-popup__content {
        top: 20%;
    }
}

// 640
@media screen and (max-width: $small) {
    .searchResult li .resultCPT {
        display: none;
    }
    .search-popup .container .search-popup__content {
        top: 15%;
    }

    .search-popup .container label {
        font-family: $font-family;
        font-size: toRem(55);
        font-weight: $font-weight-extrabold;
        line-height: 55px;
    }
}
