//======================================================================================================
// Bloc Map
//======================================================================================================
.bloc-map {
    margin: 30px 0;
}

.creasit-marker {
    background-image: url($urlJpeg + "/marker.png") !important;
    background-color: rgba(0,0,0,0) !important;
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .bloc-map {
        .wrapper_map {
            height: 430px;
        }
    }
}
