.header {
    position: relative;
    z-index: 100;
    &__container {
        @include flex($justifyContent: space-between);
    }

    &__content {
        @include flex($alignItems: center);

        .container {
            margin: 0;
            max-width: 1200px;
            padding: 0;
            @include flex($alignItems: center);
        }
    }
}

//======================================================================================================
// Header Fixed
//======================================================================================================

@keyframes headerFixed {
    from {
        top: -200px;
    }
    to {
        top: 0;
    }
}
@keyframes headerFixedAdmin {
    from {
        top: -200px;
    }
    to {
        top: 32px;
    }
}

.header--fixed {
    position: fixed;
    top: 0;
    animation: 1s headerFixed;
    transition: 0.35s;
    height: 105px;
    padding: 0;
    width: 100vw;
    background-color: $color-gray;

    .nav-main-buttons__menu {
        &:hover {
            background-color: $color-white;
        }
    }
}

.header--fixed ~ main {
    margin-top: 105px;
}

//======================================================================================================
// Identity
//======================================================================================================
.identity {
    width: 230px;
    height: 105px;
    margin-left: 50px;

    &__title {
        @include flex($alignItems: center, $justifyContent: center);
        @include size(230px, 155px);
        background-color: $color-white;
        border-bottom-left-radius: 30px 30px;
        border-bottom-right-radius: 30px 30px;
        margin-top: 0;
        z-index: -5;
        box-shadow: 0px 0px 15px 0px #00000026;

        svg {
            @include size(160px, 100px);
            fill: $color-white;
        }

        span {
            width: 0;
            opacity: 0;
            font-size: 0.0625rem;
        }
    }
}

//======================================================================================================
// Tools
//======================================================================================================

.tools {
    width: 455px;
    height: 45px;
    z-index: 25;
    &__content {
        @include flex($alignItems: center);
        gap: 10px;
        height: 45px;

        #accesconfig {
            width: 45px;
            height: 45px;
        }
    }

    &__item {
        width: 45px;
        height: 45px;
        padding: 0;
        margin: 0;
        background-color: $color-light;
        border: none;

        svg {
            fill: $color-black;
            height: 45px;
            width: 45px;
        }

        &--search {
            display: none;

            &--disabled {
                background: rgba($color-light, 0.6) !important;
                pointer-events: none;
            }
        }

        &--accessibility {
            transition: all $duration ease-in-out;

            &:hover,
            &:focus {
                background-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }
        }
    }

    &__search {
        height: 45px;
        .search-container {
            #searchInput {
                width: 400px;
                padding: 12.5px 20px;
                border-color: rgba(183, 207, 229, 0.2);
                border-radius: $border-radius--search;

                // &::-webkit-input-placeholder {
                //     color:$color-dark;
                // }

                // &::-moz-placeholder {
                //     color:$color-dark;
                // }

                // &::-ms-placeholder {
                //     color:$color-dark;
                // }

                // &::placeholder {
                //     color:$color-dark;
                // }
            }

            .search-svg {
                background-color: transparent;
                border: none;

                svg {
                    fill: $color-dark;
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
}

#accessconfig {
    height: 45px;
}
// Style for demosaisie only
body:not(.home) {
    .tools {
        &__content {
            & > svg {
                fill: $color-bg--neutral;
            }
        }

        &::before {
            background-color: $color-bg--neutral;
        }
    }
}
// End Style for demosaisie only

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    //======================================================================================================
    // Identity
    //======================================================================================================
    .identity {
        margin-left: 40px;
    }
}

// 960
@media screen and (max-width: $medium) {
    .header {
        &--fixed {
            position: static;
        }
        &__content {
            height: 65px;
            position: fixed;
            bottom: 0;
            background-color: $color-gray;
            width: 100vw;
            left: 0;
            z-index: 26;

            .container {
                height: 100%;
                margin: 0 auto;
                width: 90%;
                padding: 0 10px;
                display: flex;
                justify-content: space-between;
            }
        }

        &__container {
            height: 75px;
        }

        &__tools-profils {
            display: flex;
            gap: 10px;
            z-index: 1;
        }
    }

    .identity {
        margin: 0 auto;
        display: flex;
        justify-content: center;

        &__title {
            margin: 0;
            width: 190px;
            height: 125px;

            svg {
                width: 125.32px;
                height: 80.65px;
            }
        }
    }

    .tools {
        width: unset;

        &__search {
            height: 45px;
            .search-container {
                display: none;
            }
        }

        &__item {
            background-color: $color-white;
        }

        &__item--search {
            display: block;
            margin-top: -44px;

            svg {
                height: 20px;
                width: 20px;
            }

            &:hover,
            &:focus {
                background-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }
        }
    }
    // .header-top {
    //     &::after {
    //         content: "";
    //         display: block;
    //         position: absolute;
    //         background-color: $color-bg--neutral;
    //         width: 100%;
    //         height: 100%;
    //         top: 0;
    //         z-index: -1;
    //     }
    // }

    // // Identity
    // .identity {
    //     display: flex;
    //     min-height: 90px;
    //     height: auto;
    // }

    // // Tools
    // .tools {
    //     position: relative;
    //     width: 100%;
    //     top: 0;
    //     margin-bottom: -50px;

    //     &::before {
    //         position: relative;
    //         width: 100%;
    //         top: 0;
    //     }

    //     &__container {
    //         display: flex;
    //         justify-content: flex-end;
    //         position: relative;
    //         top: -3px;
    //     }

    //     &__content {
    //         margin: 0;
    //     }
    // }

    // .tools-view {
    //     top: calc(100% + 5px);
    // }
}

// 640
@media screen and (max-width: $small) {
    // Tools
    // .tools {
    //     &__container {
    //         top: -6px;
    //     }

    //     &__item {
    //         margin: 0 5px;
    //     }
    // }

    // .tools-view {
    //     &__container {
    //         .profils {
    //             margin-top: 80px;
    //             margin-right: 35px;
    //         }
    //     }
    // }
}
