@keyframes BgTop {
    0% {
        top: 0;
    }
    508% {
        top: 0;
    }
    100% {
        top: 100vh;
    }
}

.nav-main--burger {
    z-index: inherit;
    height: 45px;
    width: 116px;
    margin-left: 20px;

    &::before {
        content: "";
        position: absolute;
        top: -100vh;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: $color-black;
        opacity: 0;
        transition: opacity $duration ease-in-out;
    }

    &.js-open-menu {
        &::before {
            top: 0;
            opacity: 0.25;
        }
    }

    &:not(.js-open-menu) {
        &::before {
            animation-name: BgTop;
            animation-duration: 0.8s;
        }
    }

    // Burger menu Button
    .nav-main-buttons {
        position: relative;

        &__menu {
            z-index: 11;
            @include flex($justifyContent: flex-start, $alignItems: center);
            column-gap: 8px;
            width: 116px;
            margin: 0;
            border: 0;
            border-radius: 50px;
            // transition: all $duration $timing;
            cursor: pointer;
            background-color: $color-dark;
            height: 45px;
            padding: 12.5px 22px;

            &__text {
                font-size: toRem(16);
                font-weight: $font-weight-bold;
                color: $color-white;
                text-transform: uppercase;
                font-family: $font-family-second;
                margin-left: 2px;
            }

            // Open menu
            &--open {
                opacity: 1;
                visibility: initial;
                pointer-events: auto;
                transition: {
                    property: background-color, color;
                    duration: $duration;
                    timing-function: $timing;
                }

                // Burger menu Button icon
                .nav-main-buttons__menu__icon {
                    position: relative;
                    top: 0;
                    display: block;
                    @include size(13.5px, 2px);
                    background-color: $color-white;
                    transition: background-color $duration ease-in-out;

                    &::before,
                    &:after {
                        content: "";
                        position: absolute;
                        display: block;
                        @include size(100%);
                        background-color: $color-white;
                        transition: {
                            property: width, top, left, transform, background-color;
                            duration: $duration;
                            timing-function: $timing;
                        }
                    }

                    &::before {
                        top: 5.5px;
                    }

                    &::after {
                        top: -4.5px;
                    }
                }

                .nav-main-buttons__menu__text {
                    transition: color $duration ease-in-out;
                }

                &:hover {
                    background-color: $color-gray;

                    .nav-main-buttons__menu__icon {
                        background-color: $color-dark;

                        &::before,
                        &:after {
                            background-color: $color-dark;
                        }
                    }

                    .nav-main-buttons__menu__text {
                        color: $color-dark;
                    }
                }
            }

            // Close menu
            &--close {
                opacity: 0;
                visibility: hidden;
                pointer-events: none;

                svg {
                    fill: $color-white;
                }

                &:hover,
                &:focus {
                    svg {
                        fill: $color-white;
                    }
                }
            }
        }

        // For responsive
        &__submenu {
            display: none;
        }
    }

    // Popup menu
    &__popup {
        @include fixed($top: -100vh, $left: 0, $right: 0);
        z-index: 10;
        display: block;
        max-width: inherit;
        @include size(100%, 540px);
        background-color: $color-dark;
        z-index: 11;
        padding: 0;
        // position: relative;
        // overflow: hidden;
        overflow-y: hidden;
        transition: {
            property: top, left;
            duration: 1s;
            timing-function: $timing;
        }

        // &::before {
        //     content: "";
        //     @include absolute($top: 0, $left: 0, $right: 20px);
        //     z-index: 1;
        //     height: 170px;
        //     background-color: $color-main;
        //     display: none;
        // }

        &:after {
            // Attention le scalX - 1 inverse les left right top et bottom
            content: "";
            position: absolute;
            right: -130px;
            bottom: -300px;
            width: 505px;
            height: 685px;
            background-color: $color-white;
            -webkit-mask-image: url($urlSpriteImage + "theme-icons/forme_fond.svg");
            mask-image: url($urlSpriteImage + "theme-icons/forme_fond.svg");
            transform: scaleX(-1);
            opacity: 0.1;
        }
    }

    &__social-links {
        width: 140px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__button {
        margin: 0;
        height: 40px;
        width: 40px;
        border-radius: 50px;
        transition: {
            property: background-color, opacity;
            duration: $duration;
            timing-function: $timing;
        }

        svg {
            width: 100%;
            height: 100%;
            fill: $color-white;
        }

        &:hover {
            background-color: rgba($color-white, 0.1);
            // TODO  EN cours
        }
    }

    // Section menu (for overflow)
    &__section {
        overflow-y: auto;
        height: 100%;
        padding: 30px 0 20px 0;
    }

    // Container menu
    &__container {
        @include flex($justifyContent: space-between, $alignItems: flex-start, $direction: column);
        margin: 0 auto !important;
        max-width: 1200px;
        padding: 0 15px !important;
        opacity: 0;
        transition: opacity 0.7s ease-in-out;
        transition-delay: 0.7s;

        &:not(.active) {
            // transition: none;
            transition-delay: 0s;
        }

        &.active {
            opacity: 1;
        }
    }

    &__head {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
    }

    // if Burger menu open
    &.js-open-menu {
        &:before {
            opacity: 0.25;
        }

        // Burger menu Button
        .nav-main-buttons__menu {
            bottom: -7px;

            // Close menu
            &--close {
                opacity: 1;
                visibility: initial;
                pointer-events: auto;
                width: 131px;
                height: 45px;
                padding: 10px 17px 10px 17px;
                border-radius: 50px;
                background-color: $color-white;
                transition: all $duration ease-in-out;
                // border: 1px solid #fff;

                svg {
                    fill: $color-dark;
                    width: 30px;
                    height: 30px;
                }

                span {
                    color: $color-dark;
                    font-family: $font-family-second;
                    font-size: 1rem;
                    font-weight: $font-weight-bold;
                    line-height: 20px;
                }

                &:hover {
                    background-color: $color-gray;
                    // border: $color-gray;

                    svg {
                        fill: $color-dark;
                    }
                }
            }

            // Open menu
            &--open {
                // opacity: 0;
                // visibility: hidden;
                pointer-events: none;
            }
        }

        // Popup menu
        .nav-main--burger__popup {
            overflow: hidden;
            top: 0;
            transition: top 0.9s cubic-bezier(0, 0, 0.2, 1);
        }

        // Main menu
        .menu {
            position: relative;
            display: flex;
        }
    }

    // Main menu
    .menu {
        display: none;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 30px;
        @include size(350px, auto);
        margin-top: 45px;
        margin-bottom: 40px;
        padding: 0 50px 0 0;
        position: relative;

        &::before {
            background-color: $color-light;
            content: "";
            height: 100%;
            right: -30px;
            position: absolute;
            top: -5px;
            width: 1px;
            opacity: 0.5;
        }

        // &::after {
        //     content: "";
        //     @include absolute($top: 0, $right: 0);
        //     @include size(1px, 100%);
        //     background-color: $color-second;
        // }

        &__item {
            @include flex($alignItems: center, $justifyContent: space-between);
            width: 100%;
            height: fit-content;

            &.menu-item-has-children {
                .menu__link {
                    &::after {
                        content: "\f345";
                        font: normal 1.25rem/1 dashicons;
                        color: rgba(255, 255, 255, 0.3);
                        transition: color $duration $timing;
                    }

                    &:hover,
                    &:focus {
                        &::after {
                            color: $color-white;
                        }
                    }
                }

                &:not(.js-open-submenu) {
                    .submenu {
                        transition-delay: 0s;
                    }
                }

                &.js-open-submenu {
                    .submenu {
                        display: block;
                        opacity: 1;
                        z-index: 10;
                    }

                    .menu__link {
                        transform: translateX(10px);
                        opacity: 1;
                    }

                    &:hover {
                        .menu__link {
                            opacity: 1;
                        }
                    }
                }
            }

            .submenu {
                display: block;
                z-index: -5;
                opacity: 0;
                transition: opacity 0.4s ease-in-out;
                transition-delay: 0.3s;
            }
        }

        &__link {
            position: relative;
            @include flex($alignItems: center, $justifyContent: space-between);
            max-width: inherit;
            @include size(100%, auto);
            color: $color-white;
            text-transform: uppercase;
            margin-left: 20px;
            position: relative;
            font-family: $font-family;
            font-size: toRem(18);
            font-weight: $font-weight-extrabold;
            line-height: 25px;
            opacity: 0.7;
            transition:
                transform $duration ease-in-out,
                opacity $duration ease-in-out;

            &::before {
                content: "";
                width: 10px;
                height: 10px;
                border-radius: 50px;
                background-color: #fff;
                position: absolute;
                left: -20px;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            &:hover {
                transform: translateX(10px);
                opacity: 1;
            }

            &::after {
                display: none;
            }
        }
    }

    // Sub menu
    .submenu {
        display: none;
        @include absolute($top: 0, $left: 100%, $right: inherit);
        z-index: inherit;
        width: 50vw;
        padding-left: 80px;
        background-color: $color-bg--transparent;
        box-shadow: none;
        max-height: 300px;
        // margin-top: 5px;

        &__nav {
            @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: flex-start);
            gap: 0px 40px;
            max-width: inherit;
            max-height: 80vh;
            height: 300px;
            padding: 0;
            align-items: flex-start !important;
            width: 740px;
        }

        &__item {
            width: auto;
            margin: 0;
            width: 350px;
            height: 50px;
            position: relative;
            display: flex;
            align-items: center;

            &::before {
                width: 26px;
                height: 26px;
                content: "";
                background-image: url($urlSpriteImage + "/base-icons/ic-nav-chevron-right.svg");
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            &:hover {
                .submenu__link {
                    transform: translateX(10px);
                }
            }
        }

        &__link {
            width: auto;
            max-width: 314px;
            position: relative;
            display: flex;
            align-items: center;
            @include size(auto);
            margin-bottom: 1px; // Fix space beetween inline-flex
            color: $color-white;
            font-family: $font-family-second;
            font-size: 1rem;
            font-weight: $font-weight-bold;
            line-height: 25px;
            transition: transform $duration ease-in-out;

            &--grandchild {
                display: flex;
                padding: 17px 10px 17px 15px;
                border-bottom: 0;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: initial;
                line-height: initial;
            }
        }
    }
}

// Button accessibility
button.avoid-main-content {
    @include absolute($bottom: 40px, $right: 40px);
    pointer-events: none;

    &:focus {
        color: $color-white;
        border-color: $color-white;
    }
}

// For no scroll on body when menu is open
body.js-open-menu {
    overflow: hidden;
}

//=================================================================================================
// Header only for burger menu on demosaisie
//=================================================================================================
// Tools
.header .tools--burger {
    top: auto;
}

// Identity
.identity.identity--burger {
    position: relative;
    z-index: 11;
    width: fit-content;
    // height: auto;
    max-width: 85%;
    min-height: 90px;

    .identity__title {
        transition: {
            property: margin-top, color;
            duration: $duration;
            timing-function: $timing;
        }
    }
}

body.js-open-menu {
    .identity__title {
        // margin-top: 30px;
        color: $color-white;
    }

    .q-access {
        right: -960px;
    }
}

//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {
    .nav-main--burger {
        // Main menu
        .menu {
            // margin-top: 150px;
            // padding: 0 30px 0 0;
            width: 300px;
            padding: 0 0px 0 0;

            &::before {
                right: -20px;
            }

            &__link {
                font-size: toRem(16);
            }
        }

        &__container {
            align-items: flex-start;
            margin: 0 auto;
            max-width: 960px;
            padding: 0 10px !important;
        }

        // Sub menu
        .submenu {
            margin-left: 60px;
            padding-left: 0;
            width: auto;

            &__nav {
                width: 580px;
                gap: 0 30px;
            }

            &__item {
                width: 275px;
                padding: 12px 0;
                height: unset;
            }

            &__link {
                width: 239px;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    // Header -- Identity
    .identity.identity--burger {
        position: inherit;
        z-index: inherit;
        max-width: 100%;
        height: auto;
    }
    body.js-active-navmainburger .identity__title {
        margin-top: 0;
        color: $color-text;
    }

    // For Responsive (no scroll on body when menu is open)
    body.js-active-navmainburger {
        overflow: hidden;
    }

    // Menu
    .nav-main--burger {
        position: initial;
        height: auto;
        z-index: 1;

        &::before {
            display: none;
        }

        // TODO : changer transition btn retour submenu pour qu'il arrive en même temps que le sous-menu

        &__button {
            height: 40px;

            &--reseaux {
                height: 50px;
                width: 50px;
            }
        }

        .nav-main-buttons {
            &.js-open-submenu .nav-main-buttons__submenu--back {
                left: calc((100% - 90%) / 2 + 10px);
            }
        }

        // if Burger menu open
        &.js-active-menu {
            // Burger menu Button
            .nav-main-buttons {
                &__menu {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 45px;
                    min-height: inherit;
                    height: 45px;
                    margin: 40px 40px 0 auto;
                    padding: 0;
                    border-radius: $border-radius--round;
                    width: 40px;
                    height: 40px;
                    margin-left: 70px;
                    padding: 1px 0 0 0;

                    &::after {
                        display: none;
                    }
                }
            }

            // Popup menu
            .nav-main--burger__popup {
                top: 0;
                overflow-x: hidden;
            }

            // And If submenu open
            .nav-main-buttons.js-active-submenu {
                .nav-main-buttons__submenu {
                    left: 0;
                }
            }

            .menu__link {
                animation: none;
            }
        }

        // If submenu open
        &.js-active-submenu {
            .nav-main--burger__section {
                overflow: hidden;
            }

            .nav-main-buttons.js-active-submenu .nav-main-buttons__submenu {
                left: 0;
            }
        }

        .nav-main-buttons__menu--close {
            width: 40px !important;
            height: 40px !important;
            border: 1px solid $color-white;

            span {
                display: none !important;
            }
        }

        .nav-main--burger__popup {
            bottom: -100vh;
            transition: bottom 0.9s cubic-bezier(0, 0, 0.2, 1);
            top: unset;
        }

        &.js-open-menu {
            .nav-main-buttons__menu--close {
                background-color: transparent;
                padding: 0;

                svg {
                    font-size: initial;
                    fill: $color-white;
                    height: 25px;
                    width: 25px;
                }
            }

            .nav-main--burger__popup {
                bottom: 0;
                transition: bottom 0.9s cubic-bezier(0, 0, 0.2, 1);
                top: unset;
            }
        }

        // Burger Buttons
        .nav-main-buttons {
            position: inherit;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 69px;

            // Burger menu Button
            &__menu {
                position: relative;
                width: fit-content;
                top: auto;
                right: auto;
                width: 116px;
                height: 50px;
                transition: none;
                bottom: 0 !important;
                transition: all $duration ease-in-out;
                height: 45px;
                padding: 0;
                display: flex;
                justify-content: center;
                background-color: $color-white;

                &--open {
                    .nav-main-buttons__menu__icon {
                        background-color: $color-dark;

                        &::before,
                        &::after {
                            background-color: $color-dark;
                        }
                    }

                    &:hover {
                        background-color: $color-dark;

                        .nav-main-buttons__menu__icon {
                            background-color: $color-white;

                            &::before,
                            &:after {
                                background-color: $color-white;
                            }
                        }

                        .nav-main-buttons__menu__text {
                            color: $color-white;
                        }
                    }
                }

                &--close {
                    &:hover {
                        border-color: $color-gray;
                    }
                }

                &__text {
                    color: $color-dark;
                }
                // &--close {
                //     width: 40px;
                //     height: 40px;
                //     background-color: $color-dark;
                //     border-radius: 50px;
                //     border: 1px solid $color-white;

                //     span {
                //         font-size: 0 !important;
                //     }

                //     svg {
                //         font-size: initial;
                //         fill: $color-white;
                //         width: 20px;
                //         height: 20px;

                //     }
                // }

                // Burger menu Button icon
                &__icon,
                &__icon::before,
                &__icon::after {
                    transition: none;
                }
            }

            // Burger menu responsive - Close submenu
            &__submenu {
                position: absolute;
                top: 0;
                left: -100vw;
                z-index: 11;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 40px;
                height: 40px;
                margin: 40px auto 0 40px;
                padding: 0;
                background-color: $color-main;
                border-radius: $border-radius--round;
                transition: all 0.6s ease-in-out !important;

                &:hover,
                &:focus {
                    background-color: $color-gray;
                    border-color: $color-gray;
                }

                svg {
                    width: 40px;
                    height: 40px;
                    fill: $color-white;
                    transform: rotate(-90deg);
                }

                &--back {
                    background-color: $color-dark;
                    border: 1px solid $color-white;
                    top: 0;
                    left: -100vw;
                    top: 30px;
                    margin: 0;
                    transition: left $duration ease-in-out;
                    z-index: 150;
                    position: fixed;

                    &:hover {
                        svg {
                            fill: $color-dark;
                        }
                    }

                    svg {
                        transform: scaleX(-1);
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        // Popup menu
        &__popup {
            height: 100%;
            left: 0;
            right: inherit;
            bottom: -100vh;
            padding: 0;

            &::before {
                height: 100px;
            }

            &:after {
                width: 315px;
                height: 430px;
                right: -90px;
                bottom: -40px;
                mask-size: cover;
            }
        }

        &__social-links {
            position: absolute;
            left: 9px;
            bottom: -124px;
            width: 147px;
        }

        // Section menu
        &__section {
            padding: 0;
            margin-top: 30px;
            width: 100%;
            height: calc(100%);
        }

        &__head {
            align-items: center;
        }

        &__buttin {
            width: 50px;
            height: 50px;
        }

        // Container menu
        &__container {
            max-width: initial;
            margin: 0;
            padding: 0;
            flex-direction: column;
            gap: 30px;
            width: 90%;
            position: relative;
            height: auto;
            opacity: 1;

            .sticky {
                display: flex;
                flex-direction: row;
                position: unset;
                gap: 10px;
                margin: 40px;

                &__link {
                    width: 93px;
                    height: 100px;
                    border-radius: 15px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                    font-family: $font-family--heading;
                    font-size: toRem(14);
                    font-weight: 500;
                    line-height: toRem(12);
                    text-align: center;
                    color: $color-white;
                    border: 1px solid $color-white;
                    background-color: $color-bg--transparent;
                    transition: all $duration ease-in-out;

                    &--push {
                        display: none !important;
                    }

                    svg {
                        width: 30px;
                        height: 30px;
                        fill: $color-second;
                    }
                }
            }
        }

        // Main menu
        .menu {
            flex-wrap: nowrap;
            width: 100%;
            height: 100%;
            margin: 0;
            gap: 25px;
            overflow: hidden;
            z-index: 1;
            display: flex;

            &::after {
                display: none;
            }

            &__item {
                &.menu-item-has-children {
                    &:focus-within .submenu,
                    &:hover .submenu {
                        display: flex;
                    }

                    &.js-active-submenu {
                        .submenu {
                            left: 0;
                            display: flex;
                            transition: all $duration ease-in-out;
                        }
                    }

                    &.js-open-submenu {
                        .submenu {
                            overflow: visible;
                            max-height: unset;
                        }
                    }
                }

                .submenu {
                    display: flex;
                    transition: left $duration ease-in-out;
                }
            }

            &__link {
                animation: none !important;
                opacity: 1;

                &:hover {
                    color: $color-white;
                }
            }

            &__svg {
                display: none;
            }

            &__nav__list {
                overflow: visible;

                &::before {
                    display: none;
                }
            }
        }

        // Sub menu
        .submenu {
            left: -100vw;
            right: inherit;
            top: 0;
            z-index: 1;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            padding: 0;
            background-color: $color-dark;
            transition: left $duration ease-in-out;
            width: calc(100vw - (5vw + 10px));
            padding-right: calc(5% + 10px);
            height: calc(100vh - 100px);
            margin-left: 0;
            transition: all 0.6s ease-in-out !important;
            opacity: 1 !important;
            max-height: unset;

            .container {
                justify-content: unset;
            }

            &__nav {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                flex-wrap: nowrap;
                gap: 10px;
                max-width: 100%;
                width: 100%;
                max-height: inherit;
                height: calc(100vh - 100px);
                overflow-y: auto;
                padding: 0 40px 40px;
                margin: 0 !important;
                padding: 0 !important;
                width: 100% !important;
            }

            &::after {
                display: none;
            }

            &__item {
                width: 100%;
                padding: 4.5px 0;
            }

            &__link {
                width: 90%;

                &::before {
                    display: none;
                }
            }
        }
    }

    // Open-submenu
    .js-open-submenu > .submenu {
        left: 0;
        top: 0;
    }

    // .js-open-submenu {

    //     .nav-main-buttons__submenu--back {
    //         left: 30px;
    //     }
    // }

    // Open-submenu
    .js-open-submenu.menu {
        overflow: visible;
    }
    .nav-main--burger__popup {
        transition: bottom 0.9s cubic-bezier(0, 0, 0.2, 1);
    }
}

// 782 -- For admin bar only
@media screen and (max-width: 782px) {
    // Admin
    .admin-bar {
        .nav-main--burger {
            .menu,
            .submenu {
                padding-top: 146px;
            }

            &__popup::before {
                height: 146px;
            }
        }
    }
}
