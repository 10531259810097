.breadcrumb {
    background-color: transparent;
    margin-top: 10px;

    ul {
        display: block;
    }

    li {
        position: relative;
        display: inline;
        color: $color-dark;
        line-height: 1.75;
        font-family: $font-family-second;
        font-size: toRem(16);
        font-weight: 400;

        a {
            transition-duration: 0.4s;
            transition-property: text-decoration-color, color;
            transition-timing-function: ease-in-out;
            text-decoration: underline;
            text-decoration-color: transparent;
            text-underline-offset: 2px;
            &:hover,
            &:focus {
                text-decoration-color: $color-dark;
            }
        }

        svg {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            fill: $color-dark;
            vertical-align: text-bottom;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {
    .breadcrumb {
        li svg {
            margin: 0;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .breadcrumb {
        li svg {
            margin: 0 -1px;
        }
    }
}
