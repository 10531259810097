.front-page {
    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .slideshow {
        &__swiper {
            border-radius: 15px;
            overflow: hidden;
            height: 450px;
        }

        &__slide {
            &::before {
                content: "";
                @include absolute($top: 0, $left: 0);
                z-index: 1;
                @include size(100%, 100%);
                background: linear-gradient(
                    241.07deg,
                    rgba(0, 0, 0, 0) 34.06%,
                    rgba(0, 0, 0, 0.279) 71.23%,
                    rgba(0, 0, 0, 0.3) 86.05%
                );
            }
        }

        &__title {
            margin: 0;
            position: absolute;
            bottom: 90px;
            left: 40px;
            z-index: 30;
            display: flex;
            flex-direction: column;
            font-family: $font-family;
            font-size: toRem(30);
            font-weight: 400;
            line-height: 40px;
            color: $color-white;
            text-shadow: 0 0 15px rgba(0, 0, 0, 0.75);
            text-transform: uppercase;

            span {
                text-transform: none;
                font-size: toRem(40);
                font-weight: $font-weight-extrabold;
                line-height: 45px;
            }
        }

        .c-pagination {
            @include absolute($bottom: 45px, $left: 40px);
            @include flex($alignItems: center, $justifyContent: flex-end);
            gap: 10px;
            height: 25px;
            padding: 6px 15px;
            background-color: rgba($color-dark, 0.4);
            border-radius: $btn-spe-border-radius;
            z-index: 1;
            pointer-events: auto;
            backdrop-filter: blur(3px);

            .nav-swiper-autoplay {
                @include size(12px);
                padding: 0;
                background-color: transparent;
                border: 0;
                border-radius: 0;
                margin: 0;

                &__icon {
                    @include size(12px);
                    fill: $color-white;
                }

                &:hover,
                &:focus {
                    .nav-swiper-autoplay__icon {
                        fill: $color-white;
                    }
                }
            }

            &__bullets {
                @include flex($alignItems: center);
                gap: 10px;

                .swiper-pagination-bullet {
                    @include size(12px);
                    margin: 0 !important;
                    opacity: 1 !important;
                    background-color: $color-white;
                    border: 1px solid $color-white;
                    border-radius: $border-radius--round;
                    transition: {
                        property: background-color, border-color;
                        duration: $duration;
                        timing-function: $timing;
                    }

                    &:hover,
                    &:focus,
                    &-active {
                        background-color: transparent;
                        border-color: $color-white;
                    }
                }
            }
        }

        .hidden {
            display: none;
        }
    }

    //======================================================================================================
    // Access
    //======================================================================================================

    .access {
        margin-top: 60px;

        &__list {
            @include flex($alignItems: center, $justifyContent: space-between);
            flex-wrap: wrap;
            position: relative;

            &:before {
                // Attention le scalX - 1 inverse les left right top et bottom
                content: "";
                position: absolute;
                right: -295px;
                bottom: -340px;
                width: 505px;
                height: 685px;
                background-color: $color-light;
                -webkit-mask-image: url($urlSpriteImage + "theme-icons/forme_fond.svg");
                mask-image: url($urlSpriteImage + "theme-icons/forme_fond.svg");
                transform: scaleX(-1);
            }
        }

        &__item {
            &:nth-child(2) {
                .access__link::before {
                    content: "";
                    position: absolute;
                    left: -90px;
                    top: 25px;
                    width: 135px;
                    height: 50px;
                    background: url($urlSpriteImage + "motif-blanc.png") repeat;
                    background-size: 37%;
                    border-radius: 50px;
                    opacity: 0.2;
                }
            }

            &:nth-child(5) {
                .access__link::before {
                    content: "";
                    position: absolute;
                    right: -85px;
                    bottom: -10px;
                    width: 135px;
                    height: 50px;
                    background: url($urlSpriteImage + "motif-blanc.png") repeat;
                    border-radius: 50px;
                    opacity: 0.2;
                    background-size: 37%;
                }
            }

            &:hover {
                .access__link {
                    background-color: $color-dark;

                    .access__svg-container {
                        span {
                            border: 1px solid $color-white;
                            background-color: $color-white;

                            svg {
                                fill: $color-dark;
                                transform: rotate(180deg);
                            }
                        }
                    }

                    .access__title-group {
                        .access__title {
                            color: $color-white;
                        }
                    }
                }
            }
        }

        &__link {
            background-color: $color-gray;
            width: 170px;
            height: 150px;
            border-radius: 20px;
            display: block;
            padding: 15px 15px 20px 20px;
            overflow: hidden;
            position: relative;
            transition: all $duration ease-in-out;
        }

        &__svg-container {
            height: 50%;
            width: 100%;
            display: flex;
            justify-content: flex-end;

            span {
                @include flex($alignItems: center, $justifyContent: center);
                width: 25px;
                height: 25px;
                border: 1px solid $color-dark;
                border-radius: 50px;
                transition: all $duration ease-in-out;

                svg {
                    width: 25px;
                    height: 25px;
                    transition: all $duration ease-in-out;
                }
            }
        }

        &__title-group {
            height: 50%;
            width: 100%;
            display: flex;
            align-items: flex-end;
        }

        &__title {
            font-family: $font-family-second;
            font-size: 1rem;
            font-style: italic;
            font-weight: $font-weight-semibold;
            line-height: 20px;
            transition: all $duration ease-in-out;
        }
    }

    //======================================================================================================
    // News
    //======================================================================================================

    .news {
        margin-top: 100px;

        &__head {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: 25px;
        }

        &__navigation--prev,
        &__navigation--next {
            position: static;
            width: 40px;
            height: 40px;
            background-color: $color-main;
            border-radius: 50px;
            margin-top: 0;
            transition: all $duration ease-in-out;
            padding: 0;
            border: none;
            margin: 0;

            &:hover {
                background-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }

            svg {
                width: 30px;
                height: 30px;
                fill: $color-dark;
                transition: fill $duration ease-in-out;
            }

            &::after {
                display: none;
            }
        }

        &__navigation {
            display: flex;
            gap: 10px;
        }

        &__item {
            background-color: $color-light;
            border-radius: 20px;
            width: 370px;
            height: auto;

            &:hover {
                .news__link {
                    .news__image {
                        img {
                            transform: scale(1.1);
                        }
                    }

                    .news__infos {
                        .news__category {
                            background-color: $color-gray;
                        }
                    }
                }
            }
        }

        &__link {
            height: fit-content;
            display: block;

            img {
                border-radius: 15px;
                object-fit: cover;
                height: 100%;
                width: 100%;
                transition: transform $duration ease-in-out;
            }
        }

        &__content {
            padding: 0 20px 20px 20px;
            height: auto;
        }

        &__image {
            border-radius: 20px;
            overflow: hidden;
            height: 240px;

            &--notimg {
                background: url($urlSpriteImage + "/base-icons/posts-icons/post.svg") no-repeat #565c61;
                background-position: 50%;
                z-index: 15;
            }
        }

        &__infos {
            transform: translateY(-50%);
        }

        &__category {
            width: fit-content;
            padding: 5px 15px;
            background-color: #fff;
            border-radius: 50px;
            font-family: $font-family-second;
            font-size: toRem(14);
            font-weight: $font-weight-semibold;
            line-height: 20px;
            text-transform: uppercase;
            transition: background-color $duration ease-in-out;
        }

        &__title {
            margin: 0;
            font-family: $font-family;
            font-size: toRem(55);
            font-weight: $font-weight-extrabold;
            line-height: 55px;

            svg {
                width: 30px;
                height: 38px;
                fill: $color-main;
            }

            &--small {
                margin: 0;
                font-family: $font-family;
                font-size: toRem(22);
                font-weight: $font-weight-bold;
                line-height: 25px;
                margin-bottom: 5px;
            }
        }

        &__introduction {
            font-family: $font-family-second;
            font-size: toRem(18);
            font-weight: 400;
            line-height: 25px;
        }

        &__button-container {
            @include flex($justifyContent: flex-end);
            margin-top: 40px;
        }

        &__button {
            margin: 0;
            position: relative;
            transition: all $duration ease-out;
            border: none;
            width: 265px;

            svg {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }

            &::before {
                content: "";
                position: absolute;
                left: -25px;
                bottom: -15px;
                width: 135px;
                height: 50px;
                background: url($urlJpeg + "/motif.png") repeat;
                border-radius: 50px;
                opacity: 0.2;
                z-index: -1;
                background-size: 28%;
            }

            &:hover {
                background-color: rgba($color-gray, 1);
                color: $color-dark;
            }
        }
    }

    //======================================================================================================
    // Events
    //======================================================================================================

    .events {
        margin-top: 80px;

        &__container {
            position: relative;
            &:before {
                content: "";
                background-image: url($urlSpriteImage + "theme-icons/forme_fond.svg");
                position: absolute;
                left: -300px;
                top: -175px;
                width: 505px;
                height: 685px;
                z-index: -1;
            }
        }

        &__head {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: 30px;
        }

        &__navigation--prev,
        &__navigation--next {
            position: static;
            width: 40px;
            height: 40px;
            background-color: $color-main;
            border-radius: 50px;
            margin-top: 0;
            transition: all $duration ease-in-out;
            padding: 0;
            border: none;
            margin: 0;

            &:hover {
                background-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }

            svg {
                width: 30px;
                height: 30px;
                fill: $color-dark;
            }

            &::after {
                display: none;
            }
        }

        &__navigation {
            display: flex;
            gap: 10px;
            margin-bottom: -5px;
        }

        &__item {
            background-color: $color-light;
            border-radius: 20px;
            overflow: hidden;
            width: 570px;
            height: 180px;
        }

        &__link {
            display: flex;
            height: 100%;

            &:hover {
                .events__image {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }

        &__image {
            width: 280px;
            overflow: hidden;
            img {
                height: 100%;
                object-fit: cover;
                transition: transform $duration ease-in-out;
            }

            &--notimg {
                background: url($urlSpriteImage + "/events-icons/events.svg") no-repeat #565c61;
                background-position: 50%;
                z-index: 15;
            }
        }

        &__content {
            width: 290px;
        }

        &__date {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            background-color: $color-dark;
            color: $color-white;
            font-family: $font-family;
            font-size: toRem(25);
            font-weight: 500;
            line-height: 25px;
            text-transform: uppercase;

            p {
                span {
                    font-weight: $font-weight-extrabold;
                }

                &:nth-child(2) {
                    margin-left: 5px;
                }
            }
        }

        &__title {
            margin: 0;
            font-family: $font-family;
            font-size: toRem(55);
            font-weight: $font-weight-extrabold;
            line-height: 55px;
            display: flex;
            align-items: center;
            gap: 20px;

            svg {
                width: 30px;
                height: 38px;
                fill: $color-white;
                position: relative;
            }

            &--small {
                margin: 0 auto;
                font-family: $font-family;
                font-size: toRem(22);
                font-weight: $font-weight-bold;
                height: 120px;
                @include flex($alignItems: center);
                padding-left: 25px;
                padding-right: 25px;
            }
        }

        &__buttons {
            display: flex;
            justify-content: flex-start;
            flex-direction: row-reverse;
            gap: 20px;
            margin-top: 40px;
        }

        &__button {
            margin: 0;
            position: relative;
            height: 45px;
            width: 265px;
            border: none;
            color: $color-dark;
            font-family: $font-family-second;
            font-size: toRem(14);
            font-weight: $font-weight-bold;
            line-height: 20px;
            transition: all $duration ease-in-out;

            &:hover {
                background-color: $color-dark;
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }

            &--consult {
                background-color: $color-gray;
            }

            &--prop {
                background-color: $color-light;
                padding: 12.5px 0;
            }

            svg {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                fill: $color-dark;
            }
        }
    }

    .soc-annuaire {
        margin-top: 100px;

        &__container {
            display: flex;
            gap: 60px;
        }
    }

    .social {
        background-color: $color-white;
        border-radius: 20px;
        width: calc(50% - 160px);
        padding: 55px 50px;

        &__container {
            padding: 0;
        }

        &__title {
            font-family: $font-family;
            font-size: toRem(35);
            font-weight: $font-weight-extrabold;
            line-height: 40px;
        }

        &__text {
            font-family: $font-family-second;
            font-size: toRem(16);
            font-weight: 400;
            line-height: 25px;
            margin-top: 10px;
            width: 100%;
        }

        &__buttons {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            gap: 15px;

            div {
                display: flex;
                gap: 10px;
            }
        }

        &__button {
            margin: 0;
            height: 45px;
            border: none;
            color: $color-dark;
            transition: all $duration ease-in-out;

            &--download {
                width: 100%;
                background-color: $color-main;
                display: flex;
                gap: 10px;

                svg {
                    width: 18px;
                    height: 18px;
                }
            }

            &--newsletter {
                margin-right: 5px;
                width: 155px;
                background-color: $color-main;
                display: flex;
                gap: 10px;
                padding: 0;

                svg {
                    width: 18px;
                    height: 18px;
                }
            }

            &--reseaux {
                width: 45px;
                padding: 0;
                background-color: $color-gray;
                // TODO à réafficher à la demande du client 
                display: none;

                svg {
                    height: 45px;
                    width: 45px;
                }
            }

            svg {
                fill: $color-dark;
            }

            &:hover {
                background-color: $color-dark;
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }
        }
    }

    .annuaire {
        background-color: $color-light;
        border-radius: 20px;
        width: calc(50% + 100px);
        padding: 50px;
        position: relative;
        overflow: hidden;

        &::before {
            content: "";
            background: url($urlJpeg + "/motif.png") repeat;
            background-size: 28%;
            border-radius: 50px;
            top: 45px;
            height: 50px;
            right: -25px;
            opacity: 0.2;
            position: absolute;
            width: 135px;
        }

        &__container {
            padding: 0;
        }

        &__title {
            margin: 0;
            font-family: $font-family;
            font-size: toRem(55);
            font-weight: $font-weight-extrabold;
            line-height: 55px;
            display: flex;
            align-items: center;
            gap: 20px;

            svg {
                width: 30px;
                height: 38px;
                fill: $color-gray;
                position: relative;
            }
        }

        label,
        .annuaire__text {
            margin: 10px 0;
            font-family: $font-family-second;
            font-size: toRem(16);
            font-weight: 400;
            line-height: 25px;

            span {
                font-weight: $font-weight-bold;
            }
        }

        &__dropdown {
            cursor: pointer;
            background-color: $color-white;
            border: none;
            height: 45px;
            padding: 0 25px;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            font-size: toRem(14);
            margin-bottom: 10px;
            margin-bottom: 20px;
            border-radius: 50px;
        }

        &__select-container {
            position: relative;
            &::before {
                bottom: 0;
                content: "";
                height: 20px;
                margin: auto 0;
                position: absolute;
                right: 3px;
                top: 0;
                width: 40px;
                z-index: 0;
                background: url($urlSpriteImage + "/pictos-icons/ic_chevron_bas.svg") no-repeat $color-white;
                cursor: pointer;
            }
        }

        &__btn {
            cursor: pointer;
            background-color: $color-dark;
            color: $color-white;
            font-family: $font-family-second;
            font-size: toRem(14);
            font-weight: $font-weight-bold;
            line-height: 20px;
            width: 265px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            text-transform: uppercase;
            border-radius: 50px;
            transition: all $duration ease-in-out;

            svg {
                width: 18px;
                height: 18px;
                fill: white;
                z-index: 25;
                transition: all $duration ease-in-out;
            }

            &:hover {
                background-color: $color-gray;
                color: $color-dark;

                svg {
                    fill: $color-dark;
                }
            }
        }
    }

    .map {
        margin-top: 100px;
        background:
            linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #02122d 100%),
            url($urlJpeg + "/image_territoire.jpg") no-repeat;
        background-size: cover;
        height: 655px;
        background-position: center;

        &__container {
            display: flex;
            padding-top: 80px;
            gap: 86px;
            position: relative;
            height: 100%;

            &::before {
                content: "";
                width: 430px;
                height: 430px;
                background-image: url($urlSpriteImage + "/theme-icons/carte_situation.svg");
                background-size: cover;
                position: absolute;
                right: -70px;
                bottom: 30px;
            }
        }

        &__title {
            margin: 0;
            font-family: $font-family;
            font-size: toRem(55);
            font-weight: $font-weight-extrabold;
            line-height: 55px;
            color: $color-white;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                left: -25px;
                bottom: -20px;
                width: 135px;
                height: 50px;
                background: url($urlSpriteImage + "motif-blanc.png") repeat;
                border-radius: 50px;
                opacity: 0.2;
                background-size: 37%;
            }

            svg {
                width: 30px;
                height: 38px;
                fill: $color-white;
            }
        }

        &__infos {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 379px;
            margin-left: 20px;
            margin-top: 45px;
        }

        &__select {
            display: none;
            border-radius: 50px;
        }

        &__description {
            font-family: $font-family-second;
            font-size: toRem(20);
            font-weight: 400;
            line-height: 25px;
            color: $color-white;
            text-align: center;
            width: 330px;
            margin-top: 50px;
        }

        &__button {
            margin-top: 25px;
            width: 200px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-white;
            color: $color-white;
            font-family: $font-family-second;
            font-size: toRem(14);
            font-weight: 700;
            line-height: 20px;
            text-transform: uppercase;
            border-radius: 50px;
            gap: 10px;
            transition: all $duration ease-in-out;

            svg {
                width: 18px;
                height: 18px;
                fill: $color-white;
                transition: fill $duration ease-in-out;
            }

            &:hover {
                background: $color-gray;
                color: $color-dark;
                border-color: $color-gray;

                svg {
                    fill: $color-dark;
                }
            }
        }

        &__numbers-list {
            margin-top: 55px;
            display: flex;
            gap: 40px;
        }

        &__number-item {
            color: $color-white;
            font-family: $font-family-second;
            font-size: toRem(14);
            font-weight: $font-weight-bold;
            line-height: 20px;
            text-transform: uppercase;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            min-width: 85px;

            span {
                font-size: toRem(40);
                font-weight: $font-weight-extrabold;
                line-height: 40px;
            }
        }

        &__image {
            width: 485px;
            height: 495px;
            position: relative;

            svg {
                a {
                    path {
                        transition: all 0.2s ease-in-out;
                    }
                    &:hover {
                        path {
                            fill: $color-main;
                            fill-opacity: 1;
                        }
                    }
                }
            }
        }

        &__tooltip {
            position: absolute;
            display: none;
            z-index: 2;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: $font-size--text-small;
            line-height: toRem(20);
            font-weight: $font-weight-semibold;
            color: $color-dark;
            text-transform: uppercase;
            background-color: $color-white;
            padding: 10px;
            pointer-events: none;
            transform: translate(-50%, calc(-100% - 20px));
            border-radius: 10px;

            &::after {
                content: "";
                position: absolute;
                bottom: -8px;
                @include size(20px);
                background-color: $color-white;
                transform: rotate(45deg);
                z-index: 1;
                border-radius: 5px;
            }
            &.active {
                display: flex;
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .front-page {
        .slideshow {
            &__swiper {
                height: 400px;
            }

            &__title {
                bottom: 75px;
                left: 30px;

                span {
                }
            }

            &__container {
                height: 100%;

                img {
                    height: 100%;

                    object-fit: cover;
                    object-position: center;
                }
            }

            .c-pagination {
                left: 30px;
                bottom: 30px;

                .nav-swiper-autoplay {
                    &__icon {
                    }

                    &:hover,
                    &:focus {
                        .nav-swiper-autoplay__icon {
                        }
                    }
                }

                &__bullets {
                    .swiper-pagination-bullet {
                    }
                }
            }
        }

        // =============================================================================
        // Access
        // =============================================================================

        .access {
            &__list {
                &:before {
                    right: -300px;
                }
            }

            &__item {
                &:nth-child(2) {
                    .access__link::before {
                        left: -50px;
                        top: 15px;
                        width: 80px;
                        height: 40px;
                    }
                }

                &:nth-child(5) {
                    .access__link::before {
                        right: -35px;
                        bottom: -10px;
                        width: 80px;
                        height: 40px;
                    }
                }
            }

            &__link {
                width: 140px;
                height: 120px;
                padding: 10px 10px 20px 20px;
                border-radius: 15px;
            }

            &__svg-container {
                span {
                    width: 20px;
                    height: 20px;

                    svg {
                        width: 22px;
                        height: 22px;
                    }
                }
            }

            &__title {
                font-size: toRem(14);
            }
        }

        // =============================================================================
        // News
        // =============================================================================

        .news {
            &__image {
                height: 200px;
            }

            &__button-container {
                margin-top: 30px;
            }

            &__introduction {
                font-size: toRem(16);
            }
        }

        // =============================================================================
        // Events
        // =============================================================================

        .events {
            &__container {
                &::before {
                    top: -169px;
                    left: -309px;
                }
            }

            &__image {
                height: 180px;
                width: 190px;

                img {
                    width: 100%;
                }
            }

            &__date {
                width: 270px;
            }

            &__content {
                width: 270px;
            }

            &__title {
                z-index: 25;
                &--small {
                    width: 230px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

            &__buttons {
                margin-top: 30px;
            }
        }

        // =============================================================================
        // Social + Annuaire
        // =============================================================================

        .soc-annuaire {
            &__container {
                gap: 30px;
            }
        }

        .social {
            width: calc(50% - 45px);
            padding: 55px 40px;
            height: fit-content;

            &__container {
                padding: 0;
            }

            &__button {
                &--download {
                    width: 325px;
                }
            }
        }

        .annuaire {
            width: calc(50% + 15px);
            padding: 40px;

            &::before {
                width: 100px;
                height: 40px;
            }

            &__container {
                padding: 0;
            }

            &__dropdown {
                margin-bottom: 15px;
            }
        }

        // =============================================================================
        // Map
        // =============================================================================

        .map {
            height: 560px;
            &__container {
                gap: 26px;

                &::before {
                    width: 365px;
                    height: 365px;
                    right: -110px;
                    bottom: 30px;
                }
            }

            &__infos {
                margin-left: 0;
                margin-top: 10px;
            }

            &__image {
                width: 395px;
                height: 400px;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .front-page {
        .home-slideshow {
            .slideshow-container {
                width: 100%;
                max-width: unset;
                padding: 0;
            }
        }

        .slideshow {
            &__swiper {
                border-radius: 0;
                height: 340px;
            }

            &__title {
                font-size: toRem(25);
                line-height: 30px;
                left: 50px;
                bottom: 85px;

                span {
                    font-size: toRem(30);
                    line-height: 35px;
                }
            }

            &__container {
                img {
                    width: 100%;
                }
            }

            .c-pagination {
                left: 50px;
                bottom: 40px;
            }
        }

        // =============================================================================
        // Access
        // =============================================================================

        .access {
            &__container {
                @include flex($justifyContent: center);
            }

            &__list {
                justify-content: center;
                gap: 20px 20px;
                width: 600px;

                // TODO gerer la taille de l'image de background qui ne fit pas en 600px
                &:before {
                    width: 335px;
                    height: 455px;
                    right: -160px;
                    bottom: -290px;
                    mask-size: contain;
                    mask-repeat: no-repeat;
                }
            }
        }

        // =============================================================================
        // news
        // =============================================================================

        .news {
            margin-top: 50px;

            &__image {
                height: 220px;
            }
        }

        // =============================================================================
        // Events
        // =============================================================================

        .events {
            &__container {
                &::before {
                    width: 335px;
                    height: 455px;
                    top: -115px;
                    left: -195px;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }

            &__item {
                width: calc(50% - 10px);
                height: auto;
            }

            &__link {
                flex-direction: column;
                height: auto;
            }

            &__image {
                width: 100%;
            }

            &__date {
                width: 100%;
            }

            &__title {
                &--small {
                    margin: 0;
                    width: unset;
                    padding-top: 19px;
                    padding-bottom: 18px;
                    height: unset;
                }
            }

            &__content {
                width: 100%;
            }
        }

        // =============================================================================
        // Social + Annuaire
        // =============================================================================

        .soc-annuaire {
            margin-top: 80px;
            &__container {
                flex-direction: column;
                gap: 80px;
                margin: 0 auto;
                max-width: unset;
                padding: 0;
            }
        }

        .social {
            width: 100%;
            padding: 0;

            &__container {
                padding: 0 10px;
            }

            &__buttons {
                flex-direction: row;
                gap: 10px;
                margin-top: 20px;

                div {
                    gap: 5px;
                }
            }

            &__text {
                font-size: toRem(14);
                width: 600px;
                line-height: 20px;
            }

            &__button {
                &--download {
                    width: 300px;
                    padding: 12.5px 20px;
                }

                &--newsletter {
                    width: 145px;
                }
            }
        }

        .annuaire {
            width: 100%;
            padding: 0;
            border-radius: 0;
            padding: 60px 0;

            &::before {
                top: 40px;
                right: -35px;
            }

            &__container {
                padding: 0 10px;
            }

            &__title {
                font-size: toRem(40);
                line-height: 45px;
                gap: 17px;

                svg {
                    width: 22.5px;
                    height: 30px;
                }
            }

            label {
                width: 450px;
            }

            label,
            .annuaire__text {
                font-size: toRem(14);
                line-height: 20px;
            }

            &__wrapper-select {
                width: 450px;
            }

            &__dropdown {
                margin-bottom: 20px;
                border-radius: 50px;
            }

            &__btn {
                width: 300px;
            }
        }

        // =============================================================================
        // Map
        // =============================================================================

        .map {
            margin-top: 0;
            height: 520px;
            &__container {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 60px;

                &::before {
                    width: 220px;
                    height: 220px;
                    left: calc(50% + 95px);
                    top: 85px;
                }
            }

            &__title {
                font-size: toRem(40);
                line-height: 45px;
                display: flex;
                align-items: center;
                gap: 13px;

                svg {
                    width: 22.5px;
                    height: 30px;
                }

                &:before {
                    width: 100px;
                    height: 40px;
                    left: -15px;
                    bottom: -15px;
                }
            }

            &__select {
                display: block;
                margin-top: 70px;
                font-family: $font-family-second;
                font-size: toRem(14);
                font-weight: bold;
                line-height: 20px;
                padding-left: 25px;
            }

            &__infos {
                margin-left: 0;
                margin-top: 0;
                width: 450px;
            }

            &__description {
                margin-top: 30px;
                font-size: toRem(18);
                width: 450px;
            }

            &__select {
                width: 100%;
                height: 45px;
                background-color: $color-white;
                text-transform: uppercase;
                color: $color-dark;
            }

            &__select-container {
                width: 100%;
                position: relative;

                &::before {
                    bottom: 11px;
                    content: "";
                    height: 20px;
                    position: absolute;
                    right: 3px;
                    width: 40px;
                    z-index: 0;
                    background: url($urlSpriteImage + "/pictos-icons/ic_chevron_bas.svg") no-repeat $color-white;
                    cursor: pointer;
                }
            }

            &__button {
                margin-top: 30px;
            }

            &__image {
                display: none;
            }

            &__numbers-list {
                margin-top: 30px;
                gap: 30px;
            }

            &__number-item {
                font-size: toRem(12);
                min-width: 75px;
                gap: 0;

                span {
                    font-size: toRem(30);
                    line-height: 35px;
                }
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .front-page {
        .slideshow {
            &__swiper {
                border-radius: 0;
                height: 180px;
            }

            &__title {
                position: static;
                font-size: toRem(18);
                line-height: 25px;
                color: $color-dark;
                text-shadow: none;

                span {
                    font-size: toRem(22);
                    line-height: 30px;
                    color: $color-dark;
                }
            }

            &__container--div {
                img {
                    width: 100%;
                }
            }

            .container--text {
                padding-top: 10px;
            }

            .c-pagination {
                bottom: 80px;
                left: 42px;
                height: 20px;
                padding: 6px 10px;

                &__bullets {
                    .swiper-pagination-bullet {
                        height: 10px;
                        width: 10px;
                    }
                }
            }
        }

        // =============================================================================
        // Access
        // =============================================================================

        .access {
            &__list {
                gap: 15px 15px;
                &:before {
                    width: 315px;
                    height: 430px;
                    right: -102px;
                    bottom: -265px;
                }
            }
        }

        // =============================================================================
        // News
        // =============================================================================

        .news {
            margin-top: 80px;
            &__container {
                position: relative;
            }

            &__head {
                margin-bottom: 15px;
            }

            &__title {
                font-size: toRem(40);
                line-height: 45px;
                display: flex;
                align-items: center;
                gap: 18px;
                margin-left: 4px;

                svg {
                    width: 22.5px;
                    height: 30px;
                }
            }

            &__image {
                height: 190px;
            }

            &__navigation {
                position: absolute;
                bottom: 65px;
                left: 10px;
            }

            &__link {
                img {
                    width: 100%;
                }
            }

            &__button-container {
                margin-top: 75px;
                justify-content: flex-start;
            }

            &__button::before {
                height: 40px;
                left: -15px;
                width: 100px;
            }

            &__introduction {
                font-size: toRem(14);
                line-height: 20px;
            }

            &__button {
                width: 300px;
            }
        }

        // =============================================================================
        // Events
        // =============================================================================

        .events {
            &__container {
                position: relative;

                &::before {
                    display: none;
                }
            }

            &__head {
                margin-bottom: 15px;
            }

            &__title {
                font-size: toRem(40);
                line-height: 45px;
                margin-left: 2px;

                svg {
                    width: 22.5px;
                    height: 30px;
                    fill: $color-main;
                }

                &--small {
                    width: unset;
                    padding-bottom: 25px;
                    padding-top: 25px;
                    padding-left: 25px;
                    padding-right: 25px;
                }
            }

            &__navigation {
                position: absolute;
                bottom: 125px;
                left: 10px;
            }

            &__content {
                width: 100%;
            }

            &__link {
                img {
                    width: 100%;
                }
            }

            &__item {
                border-radius: 15px;
            }

            &__image {
                height: 160px;
            }

            &__buttons {
                margin-top: 75px;
                flex-direction: column;
                justify-content: flex-start;
                gap: 10px;
            }

            &__button {
                width: 300px;
            }

            &__introduction {
                font-size: toRem(14);
            }
        }

        // =============================================================================
        // Social + Annuaire
        // =============================================================================

        .social {
            &__text {
                width: unset;
            }

            &__buttons {
                flex-direction: column;
            }

            &__button {
                &--download {
                    width: 300px;
                    padding: 0 20px;
                }

                &--newsletter {
                    width: 145px;
                }
            }
        }

        .annuaire {
            &__wrapper-select {
                width: unset;
                max-width: 450px;
            }
        }

        .map {
            &__infos {
                max-width: 450px;
                width: auto;
            }

            &__select {
                width: 100%;
                cursor: pointer;
            }

            &__description {
                width: 100%;
            }
        }
    }
}

// 468
@media screen and (max-width: 467px) {
    .front-page {
        .map {
            height: 545px;
        }
    }
}

// 360
@media screen and (max-width: $x-small) {
    .front-page {
        .slideshow {
            .c-pagination {
                left: 30px;
            }
        }

        // =============================================================================
        // Events
        // =============================================================================

        .events {
            &__button {
                width: 100%;
            }

            &__buttons {
                margin-top: 72px;
            }
        }

        // =============================================================================
        // Social + annuaire
        // =============================================================================

        .social {
            &__container {
                margin: 0 auto;
                padding: 0 10px;
                max-width: 90%;
            }
        }

        .annuaire {
            &__container {
                margin: 0 auto;
                padding: 0 10px;
                max-width: 90%;
            }

            &__btn {
                width: 100%;
            }
        }

        // ============================================================================
        // Map
        // ============================================================================

        .map {
            height: 545px;

            &__numbers-list {
                gap: 25px;
            }
        }
    }
}
