.profils {
    z-index: 10;
    &__button {
        z-index: 11;
        position: fixed;
        // right: -78px;
        right: 0;
        top: 305px;
        width: 128px;
        height: 50px;
        border-radius: 10px 0px 0px 10px;
        background-color: $color-white;
        padding: 0;
        overflow: hidden;
        color: $color-dark;
        border: none;
        box-shadow: 0px 1px 10px 0px #00000014;
        margin: 0;
        transition: right $duration ease-in-out;

        &.hidden {
            right: -78px;
        }

        &:hover {
            right: 0;
        }

        &:focus {
            background-color: $color-white;
        }

        span {
            @include flex($alignItems: center, $justifyContent: center);
        }

        span:nth-child(1) {
            width: 50px;
            height: 100%;
            background-color: $color-main;

            svg {
                fill: $color-dark;
            }
        }

        span:nth-child(2) {
            width: auto;
            height: 100%;
            color: $color-dark;
            text-transform: uppercase;
            font-weight: $font-weight-semibold;
            font-size: toRem(16);
            width: calc(128px - 50px);
            background-color: $color-white;
        }
    }

    &__menu {
        position: fixed;
        right: -190px;
        top: 305px;
        width: 190px;
        height: auto;
        background-color: $color-main;
        border-radius: 10px 0px 0px 10px;
        z-index: 20;
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        box-shadow: 0px 1px 10px 0px #00000014;

        transition: right $duration ease-in-out;

        &.open {
            right: 0;
        }
    }

    &__close {
        width: 25px;
        height: 25px;
        border-radius: 50px;
        background-color: $color-main;
        border: 1px solid $color-dark;
        margin: 0;
        padding: 0px 0px 0 0;
        transition: all $duration ease-in-out;
        cursor: pointer;

        svg {
            fill: $color-dark;
            width: 8px;
            height: 8px;
        }

        &:hover {
            background-color: $color-dark;
            border: none;

            svg {
                fill: $color-white;
            }
        }
    }

    &__container {
        width: 100%;
        margin-top: -10px;
    }

    &__title {
        margin: 0;
        font-family: Open Sans;
        font-size: toRem(16);
        font-weight: $font-weight-semibold;
        line-height: 20px;
        color: $color-dark;
        opacity: 0.5;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    &__link {
        font-family: Open Sans;
        font-size: toRem(14);
        font-weight: $font-weight-bold;
        line-height: 20px;
        transition: transform $duration ease-in-out;
        width: fit-content;
        display: flex;
    }

    &__item {
        border-top: 1px solid rgba($color-dark, 0.5);
        width: 100%;
        padding: 10px 0;
        font-weight: $font-weight-bold;

        &:hover {
            .profils__link {
                transform: translateX(5px);
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .profils {
        &__button {
            top: 280px;
        }

        &__menu {
            top: 280px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .profils {
        &__menu {
            transition: bottom $duration ease-in-out;

            &::before {
                content: "";
                position: absolute;
                bottom: -6px;
                left: 95px;
                display: block;
                width: 30px;
                height: 30px;
                background-color: $color-main;
                border-radius: 5px;
                transform: translateX(-50%) rotate(45deg);
                pointer-events: none;
                z-index: -1;
            }

            &.open {
                left: calc(5% + 90px);
                border-radius: 10px;
                bottom: 75px;
                top: unset;
                right: unset;
            }
        }

        &__button {
            position: static;
            border-radius: 50px;
            @include flex($justifyContent: center, $alignItems: center);
            gap: 10px;
            height: 45px;
            transition: all $duration ease-in-out;

            // span {
            //     transition: all $duration ease-in-out;
            // }

            span:nth-child(1) {
                background-color: transparent;
                width: auto;

                svg {
                    height: 22px;
                    width: 22px;
                }
            }

            span:nth-child(2) {
                width: auto;
            }

            &:hover {
                background-color: $color-dark;

                span:nth-child(1) {
                    // background-color: $color-dark;
                    // color: $color-white;

                    svg {
                        fill: $color-white;
                    }
                }

                span:nth-child(2) {
                    // background-color: $color-dark;
                    // color: $color-white;

                    svg {
                        fill: $color-white;
                    }
                }
            }
        }
    }
}

// 700
@media screen and (max-width: $small) {
    .profils {
        &__button {
            width: 45px;
            height: 45px;

            span:nth-child(1) {
            }

            span:nth-child(2) {
                display: none;
            }
        }

        &__menu {
            top: 280px;

            &::before {
                bottom: -6px;
                left: 90px;
            }

            &.open {
                left: calc(5% + 53px);
                border-radius: 10px;
                bottom: 65px;
            }
        }
    }
}
