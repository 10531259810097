.footer {
    background-color: $color-dark;
    height: 365px;
    color: $color-white;
    border-top: 1px solid $color-light;

    &__container {
        padding-top: 80px;
        padding-bottom: 80px;
        display: flex;
        align-items: flex-start;
        gap: 150px;
    }

    &__infos,
    &__opening-hours,
    &__buttons {
        width: 300px;
    }

    &__title {
        font-family: $font-family;
        font-size: toRem(22);
        font-weight: 400;
        line-height: 25px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        span {
            font-size: toRem(22);
            font-weight: $font-weight-bold;
            line-height: 25px;
        }
    }

    &__text {
        margin-top: 20px;
        font-family: $font-family-second;
        font-size: toRem(16);
        font-weight: 400;
        line-height: 25px;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 15px;
        width: 220px;
        position: relative;
        z-index: 1;

        .button--secondary {
            transition: all $duration ease-in-out;

            &:hover {
                background: $color-gray;
                // color: $color-white;

                svg {
                    fill: $color-dark;
                }
            }
        }
    }

    &__btn {
        height: 45px;
        width: 220px;
        margin: 0;
        gap: 6px;
    }

    &__phone {
        background-color: $color-main;
        position: relative;

        &::after {
            display: block;
            content: "";
            position: absolute;
            right: -25px;
            bottom: -15px;
            width: 135px;
            height: 50px;
            background: url($urlSpriteImage + "motif-blanc.png") repeat;
            background-size: 37%;
            border-radius: 50px;
            opacity: 0.2;
            z-index: -1;
        }
    }

    &__menu {
        background-color: $color-dark;

        &__list {
            display: flex;
            justify-content: space-between;
        }

        &__item {
            font-family: $font-family-second;
            font-size: toRem(14);
            font-weight: 400;
            line-height: 20px;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 1px;
                background: currentColor;
                top: 100%;
                left: 0;
                pointer-events: none;
                transform-origin: 100% 50%;
                transform: scale3d(0, 1, 1);
                transition: transform 0.5s ease-in-out;
            }

            &:hover {
                &::before {
                    transform-origin: 0% 50%;
                    transform: scale3d(1, 1, 1);
                }
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .footer {
        &__container {
            gap: 60px;
        }

        &__buttons {
            width: 220px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        height: calc(565px + 65px);

        &__container {
            flex-direction: column;
            padding-top: 60px;
            padding-bottom: 60px;
            gap: 30px;
        }

        &__text {
            margin-top: 10px;
        }

        &__menu {
            &__list {
                justify-content: unset;
                flex-wrap: wrap;
                gap: 10px 35px;
            }
        }

        .footer__buttons {
            flex-direction: row;
            width: 100%;
            margin-top: 0;
        }

        &__phone {
            &::before {
                width: 100px;
                height: 40px;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {
        height: auto;

        &__buttons {
            flex-wrap: wrap;
        }

        &__menu {
            padding-bottom: 125px;
        }
    }
}
